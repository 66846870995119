<template>
	<div
		class="flex-column full-height bg-gray-light position-relative"
	>

		<div class="text-center under-line bg-eee pa-10">
			<DatePicker :date="item_search.sDate" @click="setDate($event)" ></DatePicker>
		</div>


		<div class="bg-white  size-px-16">
			<h6 class="text-center">예약 결제</h6>
			<hr class="top-line"/>
			<div
				class=" justify-space-between-in-box pa-20-10"
			>
				<div>
					<span class="font-weight-bold">전체 </span>
					<span>{{ item_order_payment.c_total | makeComma}} 건 / {{ item_order_payment.sum_total | makeComma}}원</span>
				</div>
				<div class="mt-10">
					<span class="font-weight-bold">결제 대기 </span>
					<span>{{ item_order_payment.c_wait | makeComma}} 건 / {{ item_order_payment.sum_wait | makeComma}}원</span>
				</div>
				<div class="mt-10">
					<span class="font-weight-bold">결제 실패</span>
					<span class="color-red-light">{{ item_order_payment.c_fail | makeComma}} 건 / {{ Number(item_order_payment.sum_fail) * -1 | makeComma}}원</span>
				</div>
				<div class="mt-10">
					<span class="font-weight-bold">결제 취소</span>
					<span class="color-red-light">{{ item_order_payment.c_cancel | makeComma}} 건 / {{ Number(item_order_payment.cancel_amount) * -1 | makeComma}}원</span>
				</div>
				<div class="mt-10">
					<span class="font-weight-bold">결제 완료 </span>
					<span class="color-blue">{{ item_order_payment.c_success | makeComma}} 건 / {{ item_order_payment.sum_success | makeComma}}원</span>
				</div>
			</div>
		</div>

		<div class="bg-white  size-px-16 mt-10">
			<h6 class="text-center">추가 결제</h6>
			<hr class="top-line"/>
			<div
				class="bg-white justify-space-between-in-box pa-20-10 size-px-16 "
			>
				<div class="mt-10">
					<span class="font-weight-bold">승인 완료 </span>
					<span class="color-blue">{{ item_order_payment.ca_success | makeComma}} 건 / {{ item_order_payment.add_success | makeComma}}원</span>
				</div>
			</div>
		</div>

		<div
			class="full-height overflow-y-auto bg-gray-light pb-50 mt-10"
		>
			<ul>
				<li
					v-for="(shop, s_index) in shop_list"
					:key="'shop_' + s_index"
					class=" mb-10 bg-white size-px-16"
				>
					<div class="pa-10 pb-10">
						<div class="justify-space-between items-center">
							<h6>상점명</h6>
							<div class="">{{ shop.shop_name }}</div>
						</div>
					</div>

					<div
						v-if="is_agency && $common.getPowNumber(shop.sales_type, 2)"
						class="justify-space-between items-center pa-10 top-line"
					>
						<h6>수기결제</h6>
						<button
							@click="toPayment(shop)"
							class="btn-inline btn-primary pa-5-20 size-px-13"
						>결제하기<v-icon v-if="false" class="color-blue" large>mdi-pencil-circle</v-icon></button>
					</div>
				</li>
			</ul>
		</div>

	</div>
</template>

<script>

import DatePicker from "../../components/DatePicker";

export default{
	name: 'IndexUser'
	, components: {DatePicker}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, bottom: true
				, top: true
			}
			, item: {
				user_balance: 0
				, settlement_balance: 0
				, deposit_balance: 0
			}
			, items_shop: []
			, item_wallet: {}
			, item_account: {
				bank_code: ''
				, shop_uid: ''
			}
			, is_on_wallet: false
			, is_on_account: false
			, is_on_holder: false
			, is_on_withdrawal: false
			, item_shop: {}
			, company_fee: 0
			, agency_fee: 0
			, is_on_payment: false
			, is_on_send: false
			, item_search: {
				sDate: this.$route.params.sDate ? this.$route.params.sDate : this.$date.getToday('-')
			}
			, item_order_payment: {

			}
		}
	}
	,computed: {
		shop_list: function(){
			return this.items_shop.filter( (item) => {
				if(item.virtual_account){
					item.shop_wallet = item.virtual_account
				}else{
					item.is_btn_wallet = true
				}

				if(item.bank_account){
					this.user.bank_list.filter( (bank) => {
						if(item.bank_code == bank.bankId){
							item.bank_name = bank.bankName
						}
					})
					item.shop_account = item.bank_name + ' ' + item.bank_account + ' ' + item.bank_holder
				}else{
					item.is_btn_account = true
				}

				if(item.shop_balance <= 0 || item.is_block){
					item.is_disabled = true
				}
				return item
			})
		}
		, is_wallet: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 1)){
				this.items_shop.filter( (item) => {
					if(this.$common.getPowNumber(item.sales_type, 1)){
						t = true
					}
				})
			}
			return t
		}
		, is_payment: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 2)) {
				t = true
			}

			return t
		}
		, is_card: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 3)) {
				this.items_shop.filter((item) => {
					if (this.$common.getPowNumber(item.sales_type, 3)) {
						t = true
					}
				})
			}
			return t
		}
		, is_manage: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 4)){
				t = true
			}
			return t
		}
		, is_agency: function(){
			let t = false
			if(this.user.agency_type == 'agency'){
				t = true
			}
			return t
		}
		, is_distributor: function(){
			let t = false
			if(this.user.agency_type == 'distributor'){
				t = true
			}
			return  t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getUserMainInfo'
					, data: this.item_search
				})

				if (result.success) {
					this.items_shop = result.data.shop_list
					this.item_order_payment = result.data.order_payment
					this.company_fee = result.data.company_fee
					this.agency_fee = result.data.upper_fee
					this.$bus.$emit('addUserData', { key: 'bank_list', val: result.data.bank_list})
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				setTimeout( () => {
					this.$bus.$emit('on', false)
				}, 500)
			}
		}
		, postWallet: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postWallet'
					, data: {
						shop_uid: this.item_wallet.shop_uid
					}
				})

				if (result.success) {
					this.offWallet()
					await this.getData()
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWallet: function(item){
			this.item_wallet = item
			this.is_on_wallet = true
		}
		, offWallet: function(){
			this.item_wallet = {}
			this.is_on_wallet = false
		}

		, postHolder: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: this.item_account
				})

				if (result.success) {
					this.is_on_holder = true
					this.item_account.bank_holder = result.data.realBankOwnerName
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'check':
						this.postAccount(call)
						break;
				}
			})
		}
		, postCheck: function(){
			if(this.is_on_holder){
				this.onPin()
			}else{
				this.postHolder()
			}
		}
		, onAccount: function(item){
			this.item_account.shop_uid = item.shop_uid
			this.is_on_account = true
		}
		, offAccount: function(){
			this.item_account = {
				bank_code: ''
				, shop_uid: ''
			}
			this.is_on_holder = false
			this.is_on_account = false
		}
		, postAccount: function(){

			this.offAccount()
			this.getData()
		}
		, getBalance: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getBalance'
				})

				if (result.success) {
					this.item.user_balance = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWithdrawal: function(shop){
			this.item_shop = shop
			this.is_on_withdrawal = true
		}
		, offWithdrawal: function(){
			this.item_shop = {}
			this.is_on_withdrawal = false
		}
		, onSend: function(shop){
			this.item_shop = shop
			this.is_on_send = true
		}
		, offSend: function(){
			this.item_shop = {}
			this.is_on_send = false
		}
		, toPayment: function(shop){
			if(this.$common.getPowNumber(shop.sales_type, 3)){
				this.$bus.$emit('to', { name: 'PaymentAgree', params: {shop_uid: shop.shop_uid}})
			}else{
				this.$bus.$emit('to', { name: 'PaymentItem', params: {shop_uid: shop.shop_uid}})
			}
		}
		, toVirtual: function(){
			this.$bus.$emit('to', { name: 'VirtualIndex'})
		}
		, setDate: function(date){
			this.item_search.sDate = date
			this.getData()
		}
	}
	,created: function(){
		this.getData()

		this.item_shop = this.user.shop_info
		this.program.title = this.item_shop.shop_name
		this.$set(this.program, 'shop_logo', this.item_shop.shop_logo)
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

